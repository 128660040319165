import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section/Section';

const NotFoundPage = () => (
    <Layout>
        <main className="main-content full-height">
            <Section title="Strona nie istnieje" />
        </main>
    </Layout>
);

export default NotFoundPage;
